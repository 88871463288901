import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kim/Documents/MedGrocer/ep-mg-website/src/components/Layout/Layout.js";
import { graphql } from "gatsby";
import SEO from "layout/SEO";
import Completed from "../../components/Completed/Completed";
import completedGIF from "../";
import updated_completed from "../../../static/images/pages/updated_completed.gif";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Submission Completed" mdxType="SEO" />
    <Completed icon={updated_completed} mdxType="Completed">
      <p className="py-2 px-5 py-2-mobile px-0-mobile">
  A MedGrocer representative will contact you through email to confirm your
  order. If you don't receive an email within the next working day, please
  contact us at{" "}
  <a href={`mailto:${process.env.GATSBY_AWS_SES_EMAIL_TO}`} className="has-text-weight-bold">
    {process.env.GATSBY_AWS_SES_EMAIL_TO}
  </a>
  .
      </p>
    </Completed>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      